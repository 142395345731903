

import Projects from '../components/projects/Projects';
import ProjectGrid from '../components/projects/grid';

function Project() {
  return (
   <>
  
   <div className="mt-16 mb-24 blur-content"> {/* Add margin to the top */}
        <Projects></Projects>
        <ProjectGrid></ProjectGrid>
        <br />
      </div>

      <div class="mobile-dev-message">
  <span className='z-10'>Mobile support is under development.</span>
</div>
  
   </>
  );
}

export default Project;
