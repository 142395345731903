import I from "../../images/image.png";

export const projects = [
  {
    title: "Ultimate BlockRegen",
    description: "A powerful plugin for regenerating blocks in Minecraft.",
    imageUrl: "https://i.postimg.cc/bNJbxyrK/UBR.png",
    buttons: [
      {
        text: "Java",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
      {
        text: "Spigot",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
    ],
    link: "https://www.spigotmc.org/resources/ultimate-blockregen.110552/",
  },
  {
    title: "Mine X Farm Regen",
    description: "A farm regeneration plugin for efficient farming.",
    imageUrl: "https://www.spigotmc.org/data/resource_icons/107/107060.jpg?1690299037",
    buttons: [
      {
        text: "Java",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
      {
        text: "Spigot",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
    ],
    link: "https://www.spigotmc.org/resources/mine-x-farm-regen-1-17-1-20.107060/",
  },
  {
    title: "ResourcePack Hub",
    description: "A central hub for managing resource packs.",
    imageUrl: "https://www.spigotmc.org/data/resource_icons/113/113337.jpg?1698824916",
    buttons: [
      {
        text: "Java",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
      {
        text: "Spigot",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
    ],
    link: "https://www.spigotmc.org/resources/resourcepack-hub.113337/",
  },
  {
    title: "Modify BrushDrop",
    description: "Customize your brush drop behavior in Minecraft.",
    imageUrl: "https://www.spigotmc.org/data/resource_icons/114/114133.jpg?1703656579",
    buttons: [
      {
        text: "Java",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
      {
        text: "Spigot",
        gradientFrom: "#4C88FF",
        gradientTo: "#007FFF",
        width: "w-12",
        height: "h-6",
        text_size: "text-[8px]",
      },
    ],
    link: "https://www.spigotmc.org/resources/modify-brush-drop.114133/",
  },

];
