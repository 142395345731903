const GradientButton = ({ text, gradientFrom, link, gradientTo, width = "w-28", height = "h-10" , text_size = "text-[12px]" }) => {
    return (
      <a 
      href={link} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="inline-block"
    >
      <div 
        className={`bg-gradient-to-r ${width} ${height} rounded-xl flex items-center justify-center shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out`} 
        style={{ backgroundImage: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})` }}
      >
        <p className={`font-arial font-bold ${text_size} text-white leading-[14px] text-gray`}>
          {text}
        </p>
      </div>
    </a>
    );
  };
  
  export default GradientButton;
  