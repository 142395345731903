import React, { useEffect, useState, useRef } from 'react';
import './Intro.css';

const Intro = ({ onFinish }) => {
  const coverTopRef = useRef(null);
  const coverBottomRef = useRef(null);
  
  // Array of images to cycle through
  const images = [
    'https://cdn.pixabay.com/photo/2017/03/19/04/19/minecraft-2155470_1280.jpg',
    'https://cdn.pixabay.com/photo/2015/03/11/21/51/minecraft-669302_1280.jpg',
    'https://cdn.pixabay.com/photo/2015/11/01/19/48/minecraft-1017472_1280.jpg',
    'https://cdn.pixabay.com/photo/2015/08/05/17/55/horse-876783_1280.png',
  ];
  
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (coverTopRef.current && coverBottomRef.current) {
        coverTopRef.current.style.transform = 'translateY(-100%)';
        coverBottomRef.current.style.transform = 'translateY(100%)';
      }

      setTimeout(() => {
        onFinish();
      }, 1500); // Wait for the animation to finish
    }, 3000); // Delay before starting the animation

    return () => clearTimeout(timer);
  }, [onFinish]);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 100); // Change image every second

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="intro-container no-scroll">
      <div className="intro-bg"></div>
      <div className="cover top" ref={coverTopRef}></div>
      <div className="cover bottom" ref={coverBottomRef}></div>
      <div className="intro-text" style={{ backgroundImage: `url(${images[imageIndex]})` }}>
        <h1>HMMBO</h1>
      </div>
    </div>
  );
};

export default Intro;
