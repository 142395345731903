import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import PCard from './PCard';
// Import required Swiper modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { items } from './items-data'
import TCard from './ThankCard';
const Thanks = () => {
  

  return (
    <div className="flex flex-col justify-center mb-[100px] items-center cursor-grab ">
      <h3 className="self-center font-sans font-black text-4xl leading-[68px] text-white mb-8">
        Thank You
      </h3>

      <div className="relative flex justify-center items-center w-[100%] h-full overflow-hidden"> {/* Set overflow-hidden here */}
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
            
          }}
          
          modules={[Autoplay]}
          loop={true}
          className="w-[100%] pt-[50px] pb-[50px]"
        >
          {items.map((item, index) => (
            <SwiperSlide 
              className="flex bg-center bg-cover overflow-hidden" // Set overflow-visible on slides
              key={index}
            >
           
        <TCard
          key={index}
          title={item.title}
          description={item.description}
          imageUrl={item.imageUrl}
          url={item.url}
          borderColor={item.borderColor}
        />
    
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default Thanks;