import { useLocation, Link } from 'react-router-dom';
import React, { useState } from "react";
import l from "../images/l.png";

const Navbar = () => {
  const location = useLocation(); // This will now work as <Router> is wrapping the component

  // State to keep track of the active link
  const [activeLink, setActiveLink] = useState(location.pathname); // You can use location.pathname to determine the current path

  // Array of links for easier management
  const links = [
    { name: "Home", href: "/" },
    { name: "Project", href: "/project" },
    { name: "Donate", href: "https://ko-fi.com/roshankumar93", target: "_blank" },
  ];

  return (
    <div className="bg-[#080808] sticky mr-2 mt-1 ml-4 top-0 z-50">
      <nav className="flex grow">
        <img src={l} alt="Logo" className="w-[56px] h-[56px] p-2 pr-1 ml-2" />
        <h1 className="grow font-inter font-black p-2 text-[25px] self-center text-white">HMMBO DEVELOPMENT</h1>
        <div className="flex self-end mr-8 self-center">
          {links.map((link) => (
            link.target ? ( // Check if it has a target (for external link)
              <a
                key={link.name}
                href={link.href}
                target={link.target}
                rel="noopener noreferrer" // Add this for security reasons on external links
                className={`font-inter text-[18px] flex p-2 self-center text-center no-underline focus:outline-none hover:scale-110 ${activeLink === link.href ? 'text-[#67b5ff] font-bold' : 'text-white font-semibold'}`}
              >
                {link.name}
              </a>
            ) : (
              <Link
                key={link.name}
                to={link.href}
                onClick={() => setActiveLink(link.href)} // Set active link on click
                className={`font-inter text-[18px] flex p-2 self-center text-center no-underline focus:outline-none hover:scale-110 ${activeLink === link.href ? 'text-[#67b5ff] font-bold' : 'text-white font-semibold'}`}
              >
                {link.name}
              </Link>
            )
          ))}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
