import React, { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import GradientButton from "../GButton";

const PCard = ({ title, description, imageUrl, buttons, link, Action = "Download" }) => {
  const tiltRef = useRef(null);

  useEffect(() => {
    if (tiltRef.current) {
      VanillaTilt.init(tiltRef.current, {
        max: 25,
        speed: 400,
        startX: 20,
        startY: -10,
        reset: true,
      });
    }
    return () => tiltRef.current?.vanillaTilt?.destroy();
  }, []);

  return (
    <div className="w-full max-w-4xl bg-black p-8 rounded-lg flex items-center justify-between border border-gray-500">
      <div className="space-y-4">
        <div>
          <h2 className="text-white text-3xl font-bold">{title}</h2>
          <p className="text-gray-400 w-80 mt-2 text-sm leading-tight">{description}</p>
        </div>
        <div
          className="bg-[#2c88ff] text-white w-20 h-6 text-[10px] rounded-md flex items-center justify-center font-bold shadow-lg hover:bg-lime-300 transition-all duration-300 ease-in-out cursor-pointer"
          onClick={() => window.open(link, "_blank")}
        >
          {Action}
        </div>
        <div className="flex grow items-center pt-8 space-x-2 w-full self-end flex-wrap gap-y-2">
          {buttons.map((button, index) => (
            <GradientButton
              key={index}
              text={button.text}
              gradientFrom={button.gradientFrom}
              gradientTo={button.gradientTo}
              width={button.width}
              height={button.height}
              text_size={button.text_size}
            />
          ))}
        </div>
      </div>
      <div
        ref={tiltRef}
        className="w-60 m-3 h-60 bg-gray-300 rounded-lg"
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover" }}
      ></div>
    </div>
  );
};

export default PCard;
