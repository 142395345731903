import React from "react";
import imagess from "../../images/dc.png";
import GradientButton from "../GButton";
import { FaDiscord, FaInstagram, FaLinkedin } from "react-icons/fa";

const Support = (props) => {
  return (
    <div className="flex w-full ml-10 h-[100vh]">
      {" "}
      {/* Changed w-[100vw] to w-full */}
      <div className="flex ml-10 flex-col justify-center">
        <h4 className="font-black text-[48px] leading-[68px] text-white">
          Support
        </h4>
        <h5 className="font-bold text-[24px] leading-[37px] text-white">
          Discord
        </h5>
        <pre className=" mt-1  leading-tight font-thin  text-[15px] text-white whitespace-pre">
        Need help or feeling confused? <br />
Join the Hmmbo Development Discord Server! <br />
Create a ticket or ask for assistance from the community.
        </pre>
        <br></br>
      
        <GradientButton 
          text="Join Now" 
          gradientFrom="#3a74f2" 
          gradientTo="#6f99f3" 
          link = "http://discord.hmmbo.com"
        />
     
        </div>

      <div className="flex items-center grow justify-center ml-48">
        <div className="relative w-[200px] h-[200px] rounded-full p-[7px] bg-gradient-to-r from-[#483bff] to-[#005eea] ">
          <img
            src={imagess}
            alt="Your Image"
            className="rounded-full w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Support;
