import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import TCard from './TCard';
// Import required Swiper modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { projects } from './projects-data'
const Carousel = () => {
  

  return (
    <div className="flex flex-col ml-20 justify-center items-center">
      <h3 className="self-start font-sans font-black  text-5xl leading-[68px] text-white mb-8">
        Projects
      </h3>

      <div className="relative flex justify-start items-center w-[95vw] h-[75vh] max-w-[1200px] max-h-[600px] overflow-hidden">
  <Swiper
    effect={'coverflow'}
    grabCursor={true}
    centeredSlides={true}
    slidesPerView={1} // Show a part of the next and previous slide
    spaceBetween={200} // Adjust space between slides
    coverflowEffect={{
      rotate: 30, // Adjust rotate for subtle effect
      stretch: 0,
      depth: 200, // Increase depth for stronger 3D effect
      modifier: 1,
      slideShadows: false,
    }}
    autoplay={{
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }}
    loop={true}
    pagination={{ clickable: true }}
    modules={[Autoplay, EffectCoverflow]}
    className="w-full h-full"
  >
    {projects.map((project, index) => (
      <SwiperSlide 
        key={index}
        className="flex justify-center items-center bg-center bg-cover"
        style={{ width: 'auto', height: 'auto' }} // Adjust sizing here
      >
        <TCard
          title={project.title}
          description={project.description}
          imageUrl={project.imageUrl}
          buttons={project.buttons}
          action={project.link}
          className="w-[300px] h-[400px]" // Example card size
        />
      </SwiperSlide>
    ))}
  </Swiper>
</div>

    </div>
  );
};

export default Carousel;