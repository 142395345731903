import React from 'react';
import Hero from '../components/home/Hero';
import About from '../components/home/About';
import Support from '../components/home/Support';
import Projects from '../components/home/Projects';
import Thanks from '../components/home/Thanks';

const Home = () => {
  return (
    <>
    <div className="mt-16 blur-content">
        
      <Hero />
      <About />
      <Projects />
      <Support />
      <Thanks />
    </div><div class="mobile-dev-message">
  <span className='z-10'>Mobile support is under development.</span>
</div>
</>
    
  );
};

export default Home;
