import React, { useState } from 'react';
import { projects } from './projects-data'; // Assuming this is your list of projects
import PTCard from './TCard';
import TCard from '../projects/TC2';

const ProjectGrid = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleTagClick = (tag) => {
    setSelectedTag((prevTag) => (prevTag === tag ? null : tag)); // Toggle tag selection
  };

  // Gather all unique button tags from the project data
  const allTags = Array.from(new Set(projects.flatMap(project => project.buttons.map(button => button.text))));

  // Filter projects by both search query and selected tag
  const filteredProjects = projects.filter((project) => {
    const matchesSearchQuery = project.title.toLowerCase().includes(searchQuery);
    const matchesTag = selectedTag
      ? project.buttons.some(button => button.text === selectedTag)
      : true; // If no tag is selected, show all projects
    return matchesSearchQuery && matchesTag;
  });

  return (
    <div className="flex flex-col items-center w-full px-4">
      {/* Top section with dynamic tags and search */}
      <div className="w-full max-w-6xl flex flex-col md:flex-row justify-between items-center py-4 mb-8">
        {/* Tags or buttons */}
        <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
          {allTags.map((tag, index) => (
            <button
              key={index}
              className={`px-3 py-1 text-sm rounded-md transition-all duration-300 ${
                selectedTag === tag
                  ? 'bg-white text-[#007FFF] shadow-lg'
                  : 'bg-gradient-to-r from-[#4C88FF] to-[#007FFF] text-white hover:shadow-lg'
              }`}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </button>
          ))}
        </div>

        {/* Search bar */}
        <div className="w-full md:w-[200px] mt-2 md:mt-0">
          <input
            type="text"
            placeholder="Search..."
            className="w-full p-2 border border-gray-400 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {/* Grid of project cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project, index) => (
            <TCard
              key={index}
              title={project.title}
              description={project.description}
              imageUrl={project.imageUrl}
              action={project.link}
              buttons={project.buttons.map((button) => ({
                ...button,
                gradientFrom: "#4C88FF", // Set the new gradientFrom
                gradientTo: "#007FFF", // Set the new gradientTo
              }))} // Update button gradients
              className="w-full h-auto text-wrap bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
            />
          ))
        ) : (
          <p className="text-center text-gray-600 col-span-full">
            No projects match your search criteria.
          </p>
        )}
      </div>
    </div>
  );
};

export default ProjectGrid;
