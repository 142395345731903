import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/home';
import Footer from './components/Footer';
import Project from './pages/Project';
import Intro from './pages/Intro'; // Import your Intro component
import "./App.css";

function App() {
  const [introDone, setIntroDone] = useState(false);

  useEffect(() => {
    // Check local storage to see if the intro has been completed before
    const introCompleted = localStorage.getItem('introDone');
    setIntroDone(introCompleted === 'true'); // Set introDone to true if it exists in local storage

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Clear local storage when the tab is hidden (switched to another tab)
        localStorage.removeItem('introDone');
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);

    };
  }, []);

  const handleIntroFinish = () => {
    setIntroDone(true);
    localStorage.setItem('introDone', 'true'); // Mark intro as done
  };

  return (
    <Router>
      <>
        <Navbar />
        <Routes>
      

          {/* Show Intro if it hasn't been completed */}
          {!introDone ? (
            <>
              <Route path="/" element={<Intro onFinish={handleIntroFinish} />} />
              <Route path="/project" element={<Project />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/project" element={<Project />} />
              {/* Redirect any unmatched routes to the Home page */}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
