import React from "react";
import imagess from "../../images/image.png";

import GradientButton from "../GButton";
import { FaDiscord, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";


const About = ({  }) => {
  return (
    <>
    <div className="relative flex m-[10px] h-[150px]"></div>
    <div className="flex">
      <div className="flex ml-10 flex-col justify-center">
        <h4 className="font-black text-[48px] leading-[68px] text-white">
          Developer
        </h4>
        <h5 className="font-bold text-[24px] leading-[37px] text-[#efefef]">
          Hmmbo
        </h5>
        <pre className="mt-1 leading-tight font-thin text-[15px] text-[#d9d9d9] whitespace-pre" style={{ fontFamily: 'Poppins, sans-serif' }}>
        <span className="mt-1 leading-tight font-light text-[18px] font-serif text-[#d9d9d9] whitespace-pre">
  Hey! I'm <span className="text-blue-300">Roshan</span>, aka <span className="text-blue-300">Hmmbo</span>! <br />
  I'm passionate about transforming ideas into reality through code. <br />
  Studying <span className="font-bold">Computer Science Engineering</span>
</span>

</pre>
        <h6 className="font-arial font-bold mt-3 text-[15px] leading-[17px] text-[#ffffff]">
          Skills
        </h6>

        <div className="flex flex-wrap w-[700px] gap-4 mt-2">
          {/* Use GradientButton for each skill with hex codes */}
          <GradientButton text="Java" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="Python" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="JavaScript" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="C#" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="C++" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="C" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="React.js" gradientFrom="#4C88FF" gradientTo="#007FFF" />
<GradientButton text="Machine Learning" gradientFrom="#4C88FF" gradientTo="#007FFF" width="w-48" />
<GradientButton text="DBMS" gradientFrom="#4C88FF" gradientTo="#007FFF" />

        </div>
      </div>

      <div className="flex items-center grow justify-center ml-0">
        <div className="relative w-[200px] h-[200px] rounded-full p-[7px] bg-gradient-to-r from-[#46c4ff] to-[#0074f8]">
          <img src={imagess} alt="Your Image" className="rounded-full w-full h-full object-cover" />
        </div>

        <div className="flex flex-col ml-4 gap-4">
  <a href="http://discord.hmmbo.com/" target="_blank" rel="noopener noreferrer">
    <button className="bg-gradient-to-b from-[#4040f2] to-[#8585e6] text-white rounded-md p-2 flex items-center gap-2 hover:scale-110">
      <FaDiscord />
    </button>
  </a>

  <a href="http://instagram.hmmbo.com/" target="_blank" rel="noopener noreferrer">
    <button className="bg-gradient-to-b from-pink-400 to-pink-600 text-white rounded-md p-2 flex items-center gap-2 hover:scale-110">
      <FaInstagram />
    </button>
  </a>

  <a href="http://youtube.hmmbo.com/" target="_blank" rel="noopener noreferrer">
    <button className="bg-gradient-to-b from-[#e33f3f] to-[#d50000] text-white rounded-md p-2 flex items-center gap-2 hover:scale-110">
      <FaYoutube />
    </button>
  </a>
</div>
      </div>
    </div>
    <div className="relative flex h-[150px]"></div>
    </>
  );
};



export default About;
