import React from "react";
import GradientButton from "../GButton";

const TCard = ({ title, description, imageUrl, buttons, link, Action = "Download" }) => {
  return (
    <div className="relative w-full max-w-4xl p-8 rounded-lg border border-gray-500 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg overflow-hidden">
      {/* Background image with blur and darken effect */}
      <div className="absolute inset-0 rounded-lg overflow-hidden">
        <div
          className="absolute inset-0 rounded-lg"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(8px)", // Apply blur effect
          }}
        ></div>
        <div className="absolute inset-0 rounded-lg bg-black opacity-50"></div> {/* Darken effect */}
      </div>

      {/* Card content */}
      <div className="relative z-10 p-8 flex flex-col justify-between h-full">
        <div className="space-y-4">
          <div>
            {/* Title from props */}
            <h2 className="text-white text-3xl font-bold hover:text-gray-300 transition duration-300">{title}</h2>
            {/* Description from props */}
            <p className="text-gray-300 w-80 mt-2 text-sm leading-tight">
              {description}
            </p>
          </div>

          {/* Action button */}
          <div
            className="bg-[#2c88ff] text-white  w-20 h-6 text-[10px] rounded-md flex items-center justify-center font-bold shadow-md hover:shadow-lg transition-all duration-300 ease-in-out cursor-pointer"
            onClick={() => window.open(link, "_blank")} // Navigate to link
          >
            {Action} {/* Display action text */}
          </div>

          {/* Buttons from props */}
          <div className="flex grow pt-4 space-x-2 w-full self-end flex-wrap gap-y-2">
            {buttons.map((button, index) => (
              <GradientButton
                key={index}
                text={button.text}
                gradientFrom={button.gradientFrom}
                gradientTo={button.gradientTo}
                width={button.width}
                height={button.height}
                text_size={button.text_size}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TCard;
