import React from "react";
import PartnershipCollab from "./Collab";

const Footer = () => {
  return (
    <footer className="relative bg-transparent flex bg-blue-400 justify-center pt-8 pb-6">
      <div className="container lg mx-auto px-4">
        <div className="flex flex-wrap lg:flex-nowrap text-left lg:justify-between">
          <div className="w-full lg:w-6/12 px-0 mb-6 pr-[100px] lg:mb-0">
          <div lassName="w-full lg:w-6/12 px-0 mb-6">   
            <h4 className="text-3xl font-semibold text-white">Thanks For Visiting</h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-400">
            Join our Discord for more information !
            </h5>
            <PartnershipCollab></PartnershipCollab>
           
          </div>
</div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex justify-end gap-14">
              <div>
                <span className="block uppercase text-gray-400 text-sm font-semibold mb-2">Stores</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-400 hover:text-white font-semibold block pb-2 text-sm" href="https://www.spigotmc.org/resources/authors/hmmbo.1670487/" target="_blank">
                      Spigot
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-400 hover:text-white font-semibold block pb-2 text-sm" href="https://builtbybit.com/creators/hmmbo.449686" target="_blank">
                      BuiltByBit
                    </a>
                  </li>
                
                </ul>
              </div>
              <div>
                <span className="block uppercase text-gray-400 text-sm font-semibold mb-2">Other Resources</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-400 hover:text-white font-semibold block pb-2 text-sm" href="http://wiki.hmmbo.com" target="_blank">
                      Wiki
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-400 hover:text-white font-semibold block pb-2 text-sm" href="http://github.hmmbo.com" target="_blank">
                      Github
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-400 hover:text-white font-semibold block pb-2 text-sm" href="http://youtube.hmmbo.com" target="_blank">
                      Youtube
                    </a>
                  </li>
              
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="my-6 border-t border-gray-600"></div>

        <div className="flex justify-center">
          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="text-sm text-gray-400 font-semibold py-1">
              Copyright © <span id="get-current-year">{new Date().getFullYear()}</span> 
              <a href="#" className="text-gray-400 hover:text-white" target="_blank" rel="noopener noreferrer">
           
              </a> by  
              <a href="#" className="text-gray-400 hover:text-white">
              {" "}  Hmmbo Development
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
