import React, { useState } from 'react';

const PartnershipCollab = () => {
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const toggleEmailVisibility = () => {
    setIsEmailVisible(!isEmailVisible);
  };

  return (
    <div className=" my-4 text-[#979797] flex-col">
      <h2 className="font-extralight text-[#979797]">Business Related Only</h2>
      {isEmailVisible && (
        <p className="mt-2 font-extralight">contact@hmmbo.com</p>
      )}
      <button
        onClick={toggleEmailVisibility}
        className="mt-2 text-white text-[#979797] font-extralight hover:underline"
      >
        {isEmailVisible ? 'Hide Email' : 'Click to See My Email'}
      </button>
      
    </div>
  );
};

export default PartnershipCollab;
