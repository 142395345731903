export const items = [
    {
      title: "KasaiSora",
      description: "Youtuber",
      url:"https://youtu.be/bc8Hu_usaFU?si=GoLfBahLwbhFnwP3",
      imageUrl: "https://yt3.ggpht.com/LcWFDSKBCrraUr-mLynWRWTi48SF9ZbHxyiXjWQuvoE43uUQc0b1qlgDW2X3X2CcjmOV5Qzb7A=s176-c-k-c0x00ffffff-no-rj-mo",
    },
    {
      title: "Server Miner",
      description: "Youtuber",
      url:"https://youtu.be/0oSu8DBi2gw?si=ZTvXLfnxyHy5wysQ",
      imageUrl: "https://yt3.googleusercontent.com/ytc/AIdro_lIwHatJcmD33o2FVZNGZUAWHkB-QG5DGExojIuAR4o8Q=s160-c-k-c0x00ffffff-no-rj"
    },
    {
      title: "DiamondXR",
      description: "Youtuber",
      url:"https://youtu.be/B70wC-jH-b8?si=LiCAns4QhvxekaJP",
      imageUrl: "https://yt3.ggpht.com/q3_31fFR7EWGuL0Te6ZkFDvBQIc1maI0xHMv9H56YtFgnvmgjSqZvML64aD4u8L4qB-hU8X03A=s88-c-k-c0x00ffffff-no-rj"
    },
    {
      title: "Indicx",
      description: "Discord Staff",
      url:"",
      imageUrl: "https://thumbs.dreamstime.com/b/detective-penguin-cartoon-character-289899767.jpg"
    },
    {
      title: "MrBogdan",
      description: "Discord Staff",
      url:"",
      imageUrl: "https://cdn.discordapp.com/avatars/596785713194729473/33fa7535ef5caf6b8197e6f249c06d3a?size=2048"
    },
   
  ];