import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import PCard from './PCard';
// Import required Swiper modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { projects } from './projects-data'
const Carousel = () => {
  

  return (
    <div className="flex flex-col justify-center items-center">
      <h3 className="self-center font-sans font-black text-4xl leading-[68px] text-white mb-8">
        Our Projects
      </h3>

      <div className="relative flex justify-center items-center w-[180%] h-full overflow-hidden"> {/* Set overflow-hidden here */}
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3} // Display 3 slides at a time
          spaceBetween={30} // Adjust space between slides
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          
          loop={true}
          pagination={true}
          modules={[Autoplay,EffectCoverflow]}
          className="w-[100%] pt-[50px] pb-[50px]"
        >
          {projects.map((project, index) => (
            <SwiperSlide 
              className="flex bg-center bg-cover overflow-hidden" // Set overflow-visible on slides
              key={index}
            >
              <PCard
                title={project.title}
                description={project.description}
                imageUrl={project.imageUrl}
                buttons={project.buttons}
                link={project.link}
                className="w-auto h-auto" // Fixed width for cards, adjust height as needed
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;